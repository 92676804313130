import React from 'react'
import { faUpload, faBroom, faExchangeAlt, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CardHome({ url, icon, title, description, isprotected = false }) {

  return (
    <a href={`${url}`} className=''>
      <div className="h-[172px] flex flex-col justify-center gap-2 bg-white px-4 rounded-md hover:bg-green-200 transition-colors  shadow-sm relative card_box">
        {isprotected && <span></span>}
        <div className="flex justify-center">
          <FontAwesomeIcon icon={icon} size="2x" className="text-excelGreen" />
        </div>
        <div className="items-center text-center text-[20px] font-medium">{title}</div>
        <p className="text-center text-sm text-gray-600">{description}</p> {/* Agregar descripción */}
        <div className="w-full">

        </div>
      </div>
    </a>
  )
}

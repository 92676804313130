import React, { useCallback } from 'react';
import { X } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

// Asegúrate de instalar estos paquetes:
// npm install @fortawesome/react-fontawesome @fortawesome/free-solid-svg-icons

function FileUploader({ files, setFiles, handleFileChange }) {
  const removeFile = useCallback((id) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  }, []);

  const truncateFileName = (name, length = 48) => {
    return name.length > length ? `${name.substring(0, length)}...` : name;
  };

  return (
    <div className="p-4">
      <div className="mb-4">
        <input
          type="file"
          onChange={handleFileChange}
          multiple
          className="hidden"
          id="file-upload"
        />

      </div>
      <div className="space-y-4">
        {files.map((file) => (
          <div key={file.id} className="bg-white p-4 rounded-lg shadow-md flex items-center space-x-4">
            <div className="w-12 h-12 bg-gray-200 flex items-center justify-center overflow-hidden">
              <FontAwesomeIcon icon={faFile} size="2x" className="text-gray-500" />
            </div>
            <div className="flex-grow">
              <p className="font-semibold text-black">{truncateFileName(file.file.name)}</p>
              <p className="text-sm text-gray-500">{(file.file.size / (1024 * 1024)).toFixed(2)} MB</p>
            </div>
            <button
              variant="ghost"
              className="h-6 w-6 bg-blue-500 rounded-md text-center justify-center flex items-center"
              onClick={() => removeFile(file.id)}
            >
              <X className="w-4 h-4 font-semibold" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FileUploader;
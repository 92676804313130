import React, { useState, useCallback } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { X } from 'lucide-react';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

// Asegúrate de instalar estos paquetes:
// npm install react-pdf react-beautiful-dnd

// Configura el worker de PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function PdfViewer({ pdfFiles, setPdfFiles, handleFileChange }) {


  const onDragEnd = useCallback((result) => {

    if (!result.destination) return;

    const items = Array.from(pdfFiles);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPdfFiles(items);

  }, [pdfFiles]);



  const removePdf = useCallback((id) => {
    setPdfFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  }, []);

  return (
    <div className="p-4">
      <div className="mb-4">
        <input
          type="file"
          accept=".pdf"
          onChange={handleFileChange}
          multiple
          className="hidden"
          id="pdf-upload"
        />
        <label htmlFor="pdf-upload" className="cursor-pointer text-black">
          Seleccionar los archivos PDF
        </label>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="pdf-list">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="space-y-4">
              {pdfFiles.map((pdfFile, index) => (
                <Draggable key={pdfFile.id} draggableId={pdfFile.id} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="bg-white p-4 rounded-lg shadow-md flex items-center space-x-4"
                    >
                      <div className="w-24 h-32 bg-gray-200 flex items-center justify-center overflow-hidden">
                        <Document file={pdfFile.file}>
                          <Page pageNumber={1} width={96} />
                        </Document>
                      </div>
                      <div className="flex-grow">
                        <p className="font-semibold text-black">{pdfFile.file.name}</p>
                        <p className="text-sm text-gray-500">{(pdfFile.file.size / (1024 * 1024)).toFixed(2)} MB</p>
                      </div>
                      <button
                        variant="ghost"
                        className="h-6 w-6 bg-blue-500 rounded-md text-center justify-center flex items-center"
                        onClick={() => removePdf(pdfFile.id)}
                      >
                        <X className="w-4 h-4 font-semibold" />
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default PdfViewer;
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import { auth } from './firebaseConfig';
import React, { forwardRef } from 'react';

import LinkNAv from './components/LinkNAv';

const Navbar = forwardRef(({ isoppennav }, ref) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth).then(() => {
      localStorage.removeItem('jwtToken');
      navigate("/login");
    }).catch((error) => {
      console.error("Error al cerrar sesión:", error);
    });
  }

  return (
    <nav
      ref={ref}
      // className="w-64 h-[95vh] bg-excelGreen text-white p-4 py-6 overflow-y-auto items-center"
      className={`bg-excelGreen shadow-sm ${!isoppennav ? "-translate-x-80" : "translate - x - 0"} fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-72 rounded-xl transition-transform duration-300 xl:translate-x-0 border border-blue-gray-100 text-white`}
    >
      <div className='p-4'>
        <button

        >

        </button>
        <h1 className="text-2xl font-bold mb-4 ">MY DATA WORKER</h1>
        <hr className="mb-4" />
        <ul>
          <LinkNAv ruta='/' titulo='Inicio' />
          {/* <LinkNAv ruta='/fileu' titulo='Subir archivo' /> */}
          {/*  <LinkNAv ruta='pdfConvert' titulo='Pdf Convert' />
        <LinkNAv ruta='dataAnalysis' titulo='Análisis de datos' /> */}


        </ul>
        <div >
          <button onClick={handleLogout}
            className='px-2 py-4'>
            Cerrar sesión
          </button>
        </div>
      </div>

    </nav>
  );
})

export default Navbar;

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/web/pdf_viewer.css';
import { useNavigate } from 'react-router-dom';

// Configuración de pdfjs
pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function AskMyData() {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [question, setQuery] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const pdfContainerRef = useRef(null);
  const chatContainerRef = useRef(null); // Ref para el contenedor del chat
  const [pdfDocument, setPdfDocument] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      navigate('/login');
    }
  }, []);


  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setPageNumber(1);
    setPdfDocument(null);
  };

  // Renderiza el PDF seleccionado
  useEffect(() => {
    const renderPdf = async () => {
      if (!file) return;
      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const typedArray = new Uint8Array(e.target.result);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
        setPdfDocument(pdf);
      };
      fileReader.readAsArrayBuffer(file);
    };

    renderPdf().catch((err) => console.error('Error rendering PDF:', err));
  }, [file]);

  useEffect(() => {
    const renderPage = async () => {
      if (!pdfDocument || !pageNumber || pageNumber <= 0 || pageNumber > pdfDocument.numPages) return;

      const page = await pdfDocument.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement('canvas');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const context = canvas.getContext('2d');

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;

      pdfContainerRef.current.innerHTML = '';
      pdfContainerRef.current.appendChild(canvas);
    };

    renderPage();
  }, [pdfDocument, pageNumber]);

  useEffect(() => {
    // Auto-scroll dentro del chat
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatMessages]);

  const handleSendMessage = async () => {
    if (!file || !question) {
      setError('Por favor, selecciona un archivo y escribe una consulta.');
      return;
    }

    setError(null);
    setLoading(true);

    // Agrega el mensaje del usuario al chat inmediatamente (simulando que ya se envió)
    setChatMessages((prevMessages) => [
      ...prevMessages,
      { user: 'You', text: question },
    ]);
    setQuery('');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('question', question);
    formData.append('page', pageNumber);

    try {
      const token = localStorage.getItem('jwtToken');
      const headers = {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post('http://localhost:8000/ask-my-pdf', formData, { headers });

      if (response.data) {
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { user: 'Bot', text: response.data.answer },
        ]);
      } else {
        setError(response.data.message || 'Error en el servidor');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Error de servidor. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(pdfDocument?.numPages || 1, prevPageNumber + 1));
  };

  const handlePrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(1, prevPageNumber - 1));
  };

  return (
    <div className="flex flex-col h-[99%] bg-gray-100">
      <div className="flex items-center justify-between p-4 bg-excelGreen text-white">
        <h1 className="text-lg font-bold">Ask My Data</h1>
      </div>
      <div className="flex flex-grow">
        <div className="w-2/3 bg-white p-4 shadow-lg overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">Sube tu archivo</h2>
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
          />
          {file && (
            <>
              <div ref={pdfContainerRef} className="mt-4 border border-gray-300 h-[500px] overflow-y-auto"></div>
              <div className="flex space-x-2">
                <button onClick={handlePrevPage} disabled={pageNumber === 1} className="px-2 py-1 bg-gray-300 hover:bg-gray-400 rounded">
                  Anterior
                </button>
                <span>Página {pageNumber}</span>
                <button onClick={handleNextPage} disabled={pageNumber === (pdfDocument?.numPages || 1)} className="px-2 py-1 bg-gray-300 hover:bg-gray-400 rounded">
                  Siguiente
                </button>
              </div>
            </>
          )}
        </div>

        <div className="w-1/3 flex flex-col h-full">
          <div
            ref={chatContainerRef}
            className="flex-grow p-4 bg-gray-50 overflow-y-auto"
            style={{ maxHeight: 'calc(100vh - 150px)' }} // Limita el alto del chat para que no ocupe toda la pantalla
          >
            <div className="space-y-4">
              {chatMessages.map((message, index) => (
                <div key={index} className={`flex ${message.user === 'You' ? 'justify-end' : 'justify-start'}`}>
                  <div
                    className={`px-4 py-2 rounded-lg max-w-xs ${
                      message.user === 'You' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'
                    }`}
                  >
                    <p className="text-sm">{message.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4 bg-white border-t">
            {loading && <p className="text-sm text-gray-500 mb-2">Analizando...</p>}
            {error && <p className="text-sm text-red-500 mb-2">Error: {error}</p>}
            <div className="flex space-x-2">
              <input
                type="text"
                placeholder="Escribe tu consulta..."
                value={question}
                onChange={(e) => setQuery(e.target.value)}
                className="flex-grow border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleSendMessage}
                disabled={loading || !file || !question}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-gray-400"
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AskMyData;

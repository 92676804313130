import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { signInWithEmailAndPassword, onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth, signInWithGooglePopup, signInWithMicrosoftPopup } from '../firebaseConfig';


import FileUploader from '../components/FileUploader';



const MySwal = withReactContent(Swal);

function ShareFile({ buttonLabel, redirectPath, title, desc, multipleFile = false, endpoint, nombreArchivo }) {
  const [showForm, setShowForm] = useState(false);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [userName, setUserName] = useState('');
  const fileInputRef = useRef(null);



  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, get the user's name
        const displayName = user.displayName || user.email;
        setUserName(displayName);
      } else {
        // User is signed out
        setUserName('');
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);


  const onDrop = (acceptedFiles) => {

    if (acceptedFiles) {

      handleFileChange({ target: { files: acceptedFiles } });
      /* if (selectedFile.type === 'application/pdf') {
        // setFile(selectedFile);
        // const url = URL.createObjectURL(selectedFile);
        // setFileUrl(url);
        // let urlOf = url.replace('blob:', '')
 
        // urloffice.current = urlOf
        // setPreview(selectedFile.name);
 
      } else {
        MySwal.fire({
          title: 'Invalid File Type',
          text: 'Please upload a valid Pdf file.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      } */
    }
  };
  const handleFileChange = useCallback((event) => {
    const files = event.target.files;

    if (files) {
      const newPdfFiles = Array.from(files).map((file) => ({
        id: Math.random().toString(36).substr(2, 9),
        file,
      }));
      setPdfFiles((prevFiles) => [...prevFiles, ...newPdfFiles]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: 'application/pdf',
  });

  const formSend = useRef({
    recipient_email: '',
    sender_name: ''
  });

  const handleUploadClick = () => {
    setShowForm(true);
  };

  const handleBackClick = () => {
    setShowForm(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    formSend.current = { ...formSend.current, [name]: value };

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      MySwal.fire({
        title: 'Subiendo Archivos',
        text: 'Por Favor espera hasta que los archivos se suban',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },


      });

      const formData = new FormData();


      pdfFiles.forEach((pdfFile, index) => {

        formData.append('files', pdfFile.file, pdfFile.file.name);
      });
      // formData.append('file', pdfFiles);
      const { recipient_email: email, sender_name: name } = formSend.current;


      const token = localStorage.getItem('jwtToken');
      const headers = {
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      };

      const response = await axios.post(`${import.meta.env.VITE_API_ENDPOINT}/file-share/${endpoint}?recipient_email=${email}&sender_name=${userName}`, formData, { headers })

      if (response.status === 200) {
        MySwal.fire({
          title: 'Archivo Enviado',
          text: 'El archivo se ha enviado correctamente',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }

    } catch (error) {
      console.error(error)
      MySwal.fire({
        title: 'Error',
        text: 'Hubo un error al enviar el archivo. Por favor, inténtalo de nuevo.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      MySwal.close();
    }
    // Aquí puedes manejar el envío del formulario
  };

  return (
    <div className="min-h-screen px-[2%] md:px-[8%] bg-[#f7f7f7] flex justify-center">
      <div className="relative w-full max-w-2xl p-6 bg-excelGreen text-white rounded-lg shadow-md">
        <h2 className="text-xl font-bold mb-4">Compartir Archivos</h2>
        <p className="text-sm mb-4">
          {desc} Comparte y recibe archivos PDF con tus destinatarios.
          <br />
        </p>




        <div className={``}>
          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div>
              <label className="block text-sm font-medium text-white">Correo del destinatario</label>
              <input type="email" defaultValue={formSend.current.recipient_email} name='recipient_email' className="mt-1 p-2 w-full rounded-md text-black" required onChange={(e) => handleChange(e)} />
            </div>
            {/* <div>
              <label className="block text-sm font-medium text-white">Nombre de quien envía</label>
              <input type="text" className="mt-1 p-2 w-full rounded-md text-black" defaultValue={formSend.current.sender_name} name='sender_name' required onChange={(e) => handleChange(e)} />
            </div> */}
            <div
              {...getRootProps()}
              className={`border-2 border-dashed border-gray-500 rounded-lg p-4 mb-4 text-center cursor-pointer ${isDragActive ? 'opacity-50' : ''
                }`}
              onClick={() => fileInputRef.current?.click()}
            >
              <input {...getInputProps()} ref={fileInputRef} />
              <p className="text-white">Arrastra y suelta tu archivo aquí, o haz clic para seleccionar un archivo</p>
            </div>
            {pdfFiles.length > 0 &&
              <>
                <div className="bg-[#E2E4E8] p-4 mb-4 rounded-lg h-max">
                  <h3 className="font-semibold mb-2 text-black">Archivos Seleccionados</h3>

                  <FileUploader
                    files={pdfFiles}
                    setFiles={setPdfFiles}
                    handleFileChange={handleFileChange}
                  />
                </div>
                <div className="flex justify-between">

                  <button type="submit" className="mt-4 p-2 bg-white text-excelGreen rounded-md hover:bg-green-200 transition-colors">
                    Subir y Enviar
                  </button>
                </div>
              </>

            }


          </form>
        </div>
      </div>
    </div>
  );
}

export default ShareFile;